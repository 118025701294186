import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const LocationPage = () => (
  <Layout>
    <SEO title="出張撮影" />
    <div
      className="flex h-[20vh] bg-cover bg-center bg-opacity-25 justify-center content-center"
      style={{ backgroundImage: `url('../location-header.webp')` }}
    >
      <p className="text-white text-center text-3xl lg:text-4xl self-center">出張撮影</p>
    </div>
    <div className="container space-y-5 px-5 lg:px-0 my-8 justify-center content-center">
      <p className="text-textblack text-xl text-center">アライヤ写真館では、同窓会・発表会・コンサートなどの出張撮影が可能です。<br/>詳しくは<Link to="/#contact">お問い合わせ</Link>ください。</p>
      <p className="text-textblack text-xl text-center">行田市周辺であれば、当日仕上げ・お渡しも場合により可能です。</p>
      
      <p className="text-textblack text-center text-lg">
        最大100人程度まで対応可能です。<br/>
      </p>
      <div className="flex flex-row gap-2">
        <StaticImage
          className="flex-initial basis-1/2"
          src="../images/location/location-1.webp"
        />
        <StaticImage
          className="flex-initial basis-1/2"
          src="../images/location/location-2.webp"
        />
      </div>
      <div className="flex flex-row gap-2">
        <StaticImage
          className="flex-initial basis-1/2"
          src="../images/location/location-3.webp"
        />
        <StaticImage
          className="flex-initial basis-1/2"
          src="../images/location/location-4.webp"
        />
      </div>
    </div>
  </Layout>
)

export default LocationPage